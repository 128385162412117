<template>
  <div>
    <!-- Title -->
    <label
      v-if="!hideTitle"
      class="text-primary-gray-700 flex mb-1.5 font-inter font-medium text-sm"
    >
      {{ translatedTitle }}
      <span v-if="rules.includes('required')" class="text-text-color-danger pl-1">*</span>
      <!-- Tooltip -->
      <div v-if="tooltip" class="-mt-0.5">
        <div
          class="flex-item ml-2 rtl:mr-2 self-center cursor-pointer"
          @click="clickToolTip"
          @mouseover="displayToolTip"
          @mouseleave="hideToolTip"
        >
          <Icon class="icon" icon="infoCircle" color="primary-grey" height="19.617" width="15.5" />
          <div v-if="showToolTip" class="-ml-24 md:-ml-7 mt-3 md:mt-2 rtl:-mr-24 md:rtl:-mr-7 z-10">
            <Tooltip
              class="w-min sm:w-auto"
              title="Multiselect Title"
              text="Multiselect Description"
              direction="left"
            />
          </div>
        </div>
      </div>
    </label>
    <!-- Main Container -->
    <div :class="parentClass">
      <Field
        v-show="false"
        v-model="isItemSelected"
        :rules="rules"
        :name="name === '' ? title : name"
        :label="getErrorMessageKey"
      />
      <section class="w-full relative">
        <UiOnClickOutside :do="onClickOutside">
          <div class="flex border rounded-md border-primary-grey">
            <div
              ref="boxContainer"
              class="h-11 p-2 justify-between px-4 flex items-center rounded-lg cursor-pointer w-full relative"
              :class="[
                inputColor,
                isErrorAvailable && 'bg-error-50 border border-error-500 rounded-md',
                isVisible && 'purple-select-box-shadow  border-primary-purple-300 border',
                { 'cursor-not-allowed': disabled },
              ]"
            >
              <div v-if="showLock" class="pt-0.5">
                <UiLock
                  :model-value="lockValue"
                  :deactivate="isLockedFromUpperLevel"
                  class="ltr:border-r rtl:border-l border-text-color-grey -ml-2 py-1 pt-2 pr-3 mr-2"
                  @update:modelValue="updateLockEvent"
                />
              </div>

              <div
                class="flex flex-row items-center w-[91%] gap-[5px]"
                :style="{ maxWidth: `${maxChipContainerWidth}px` }"
                @click.stop="toggleDropdown()"
              >
                <span
                  v-if="selectedItems.length === 0"
                  class="text-sm opacity-50 md:text-base align-baseline flex self-center gap-2 text-gray-500 font-normal text-color-primary-grey"
                >
                  <span v-if="image" class="mb-[2px] self-center">
                    <Icon
                      class="icon relative"
                      icon="dummyUser"
                      color="primary-white"
                      height="15"
                      width="13"
                    />
                  </span>

                  <span>
                    {{
                      (placeholder && $t(`placeholder.Select ${placeholder}`)) ||
                      $t(`placeholder.Select`)
                    }}
                  </span>
                </span>

                <div
                  v-for="(item, index) in selectedItems.slice(0, allowedMaxChips)"
                  :key="index"
                  :class="chipswidth(selectedItems)"
                >
                  <Chips
                    :class="chipswidth(selectedItems)"
                    :image="image"
                    :url="image && item && item.image ? item.image : null"
                    :title="getLabel(item)"
                    :name-initials="`${item.first_name} ${item.last_name}`"
                    description="Description for this item"
                  />
                </div>
                <div v-if="showBadge" class="flex items-center gap-1 justify-center">
                  <span>...</span>
                  <div
                    class="badge z-10 relative justify-center flex items-center border-2 border-primary-purple-50 bg-primary-purple-100 rounded-full text-sm md:text-base font-roboto font-medium w-6 h-6"
                  >
                    <p
                      class="items-center text-primary-purple-600 text-xs font-medium leading-[18px] p-1.5"
                    >
                      +{{ updateBadge }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex justify-end" @click.stop="toggleDropdown()">
                <Icon
                  v-if="!disabled"
                  :icon="!isVisible ? 'chevronBottom' : 'chevronUp'"
                  color="primary-gray-500"
                  height="10"
                  width="10"
                />
              </div>
            </div>

            <!-- Dropdown Section -->
            <div v-if="!disabled" class="absolute top-11 left-0 z-60 w-full">
              <div
                v-if="isVisible"
                class="border relative left-0 right-0 shadow-lg rounded-md bg-white w-full mt-2"
              >
                <div
                  v-if="searchEnable || searchThroughApi"
                  class="flex items-center gap-2 border-primary-grey border-b h-auto py-2"
                >
                  <span class="absolute ltr:left-3 rtl:right-3">
                    <Icon
                      class="icon"
                      icon="search"
                      color="primary-grey-500"
                      height="20"
                      width="20"
                    />
                  </span>
                  <input
                    v-model.trim="searchQuery"
                    class="h-11 bg-primary-gray-50 text-primary-gray-500 font-normal text-sm md:text-base focus:outline-none font-roboto px-10 w-full"
                    type="text"
                    placeholder="Search"
                    @keyup="searchThroughApi ? emitQuery : filterData"
                  />
                </div>

                <!-- Selected List -->
                <span v-if="selectedItems.length > 0">
                  <ul
                    class="whitespace-nowrap max-h-[250px] overflow-x-hidden overflow-y-auto text-start list-none border-b border-primary-grey"
                  >
                    <li
                      v-for="(item, index) in selectedItems"
                      :key="index"
                      class="flex justify-between items-center bg-primary-gray-50 my-2 h-11 p-3 text-sm md:text-base"
                    >
                      <div class="flex truncate overflow-auto items-center w-96 gap-2">
                        <UserImageDisplay :image="image" :item="item" />
                        <div
                          class="font-roboto font-regular pr-1 truncate text-black"
                          :class="fullNameClass"
                        >
                          {{ getLabel(item) }}
                        </div>
                      </div>
                      <span @click="removeSelectedItem(item)">
                        <Icon
                          class="icon dropdown-icon pr-1 cursor-pointer"
                          color="primary-purple-600"
                          icon="cross"
                          height="9"
                          width="13"
                        />
                      </span>
                    </li>
                  </ul>
                </span>
                <!-- Unselected List -->
                <div class="pt-auto w-full">
                  <div
                    v-if="optionsArray.length === 0"
                    class="empty-list text-gray-800 text-center w-full p-3 bg-primary-white text-sm md:text-base overflow-hidden"
                  >
                    {{ $t('dashboard.NO_ITEM') }}
                  </div>
                  <div v-else class="options w-full mt-2">
                    <ul
                      class="full max-h-[250px] overflow-x-hidden overflow-y-auto text-start list-none"
                    >
                      <li
                        v-for="(item, index) in filteredData"
                        :key="index"
                        class="w-full cursor-pointer text-base h-11 flex items-center"
                        :class="item.check ? '' : ' hover:bg-bg-color-light'"
                        @click="selectItem(item)"
                      >
                        <div class="flex items-center pl-3 truncate overflow-auto w-96 gap-2">
                          <UserImageDisplay :image="image" :item="item" />
                          <div class="truncate font-roboto pr-1 font-regular w-full text-black">
                            {{ getLabel(item) }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </UiOnClickOutside>
      </section>
    </div>
    <!-- ERRORS -->
    <div v-if="!noError" class="w-full info-wrapper">
      <div ref="errorContainer" class="flex justify-end h-5">
        <ErrorMessage v-slot="{ message }" :name="name === '' ? title : name">
          <small class="error-msg text-text-color-danger text-sm italic">
            <p>{{ displayError(message) }}</p>
          </small>
        </ErrorMessage>
      </div>
    </div>
  </div>
</template>

<script>
import UserImageDisplay from '@src/components/UiElements/UserImageDisplay.vue'
import Icon from '@components/icons/icon.vue'
import UiOnClickOutside from '@src/components/UiElements/UiOnClickOutside.vue'
import Tooltip from '@src/components/tooltip.vue'
import Chips from '@src/components/UiElements/Chips.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import tooltipMixin from '@/src/mixins/components/tooltip-mixin.js'
import dropdownMixin from '@src/mixins/dropdown-mixins.js'
import UiLock from '@components/UiElements/UiLockAnimation.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { accessLevel } from '@src/utils/generalUtil'
import { mapState } from 'vuex'
import validationMixin from '@src/mixins/components/validation-mixin.js'
import { Field, ErrorMessage, configure } from 'vee-validate'
import { validationConfiguration } from '@src/vee-validate/index.js'
configure(validationConfiguration)
export default {
  name: 'UiMultiSelectBox',
  components: {
    Field,
    ErrorMessage,
    UserImageDisplay,
    Icon,
    UiOnClickOutside,
    Tooltip,
    Chips,
    UiLock,
  },
  mixins: [generalUtil, tooltipMixin, dropdownMixin, validationMixin],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    noError: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    dynamicTitle: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    alreadySelected: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    filterKey: { type: String, default: '' },
    image: {
      type: Boolean,
      default: false,
    },
    inputColor: { type: String, default: '' },
    options: {
      type: Array,
      default: () => [],
    },
    parentClass: { type: String, default: 'w-full' },
    fullNameClass: {
      type: String,
      default: 'w-full',
    },
    searchThroughApi: { type: Boolean, default: false },
    title: { type: String, default: 'title' },
    label: { type: String, default: 'title' },
    url: { type: String, default: 'title' },
    detail: { type: String, default: 'description' },
    reduce: { type: String, default: '' },
    showLock: { type: Boolean, default: false },
    lockValue: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    searchEnable: { type: Boolean, default: false },
    isLockedFromUpperLevel: { type: Boolean, default: false },
  },
  emits: ['search', 'updateLockValue', 'update:modelValue', 'emitQuery'],
  data() {
    return {
      isErrorAvailable: false,
      selectedItems: [],
      boxContainerWidth: 0,
      isItemSelected: false,
    }
  },
  computed: {
    getErrorMessageKey() {
      return this.dynamicTitle ? this.title : this.$t(`placeholder.${this.title}`)
    },
    translatedTitle() {
      return !this.dynamicTitle ? this.$t(`title.${this.title}`) : this.title
    },
    ...mapState({
      tabRightBar: (state) => state.layout.tabRightNav,
      tabLeftNav: (state) => state?.layout?.tabLeftNav,
    }),
    ...mapState('layout', ['currentSectionScope', 'currentCampusScope', 'currentClassScope']),
    /**
     * Filter Data
     * @param {void}
     * @returns {array}
     * @description Function description:
     * - Filters data from list by search query and displaying it in options list.
     * - Removes the searched item from the options list.
     */
    filterData() {
      const query = this.searchQuery.toLowerCase()
      if (this.searchThroughApi) return this.optionsArray
      else
        return this.optionsArray.filter((i) => {
          return Object.values(i).some((word) => String(word).toLowerCase().includes(query))
        })
    },
    emitQuery() {
      return this.$emit('search', this.searchQuery)
    },
    showBadge() {
      return this.allowedMaxChips > 0 ? this.selectedItems.length > this.allowedMaxChips : false
    },

    filteredData() {
      let list = []
      this.filterData.forEach((item) => {
        if (
          accessLevel(
            item,
            this.currentCampusScope,
            this.currentSectionScope,
            this.currentClassScope,
          )
        )
          list.push(item)
      })
      return list
    },

    /**
     * More Than Three
     * @param {void}
     * @returns {string}
     * @description It returns the items from index 0 to 3
     */
    maxChipContainerWidth() {
      return this.selectedItems.length === 1
        ? this.boxContainerWidth - 30
        : this.boxContainerWidth - 95
    },
    allowedMaxChips() {
      return Math.floor(this.maxChipContainerWidth / 80)
    },

    /**
     * Update Badge
     * @param {void}
     * @returns {number}
     * @description Returns selectedItems length by substract 3 from it
     */
    updateBadge() {
      return this.selectedItems.length - this.allowedMaxChips
    },
  },
  watch: {
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.getBoxContainerWidth()
      },
    },
    tabLeftNav: {
      immediate: true,
      handler(value) {
        if (value) this.getBoxContainerWidth()
      },
    },
    options: {
      immediate: true,
      handler(val) {
        this.optionsArray = JSON.parse(JSON.stringify(this.options))
        if (val && !this.searchQuery) {
          this.selectedItems = []
          this.getBoxContainerWidth()
        }
      },
    },
    selectedItems: {
      deep: true,
      handler(val) {
        this.isItemSelected = val?.length ? true : false
        this.errorHandler()
      },
    },
  },
  mounted() {
    this.getBoxContainerWidth()
  },
  methods: {
    getBoxContainerWidth() {
      setTimeout(() => {
        let boxContainer = this.$refs.boxContainer
        if (boxContainer) {
          this.boxContainerWidth = boxContainer.clientWidth
          this.alreadyAssigned()
        }
      })
    },
    chipswidth(optionsArray) {
      return optionsArray.length === 1 ? 'w-full max-w-fit' : 'w-full max-w-fit min-w-[70px]'
    },
    /**
     * Get Detail
     * @param {object | *} option - Object or any other datatype
     * @return {string | *} - Returns string in case of object else returns parameter as it is.
     * @description Gets option as param and returns mentioned value of key that provided in item
     */
    getDetail(option) {
      if (typeof option === 'object') {
        return option[this.detail]
      }
      return option
    },
    toggleDropdown() {
      this.isVisible = !this.isVisible
      if (!this.isVisible && this.searchThroughApi && this.searchQuery) {
        this.searchQuery = ''
        this.$emit('search', this.searchQuery)
      }
    },

    /**
     * OnClick Outside
     * @description Closes any dropdown when click outside the dropdown area
     */
    onClickOutside() {
      this.searchQuery = ''
      if (this.isVisible && this.searchThroughApi && this.searchQuery)
        this.$emit('search', this.searchQuery)
      this.isVisible = false
    },

    /*
     * Get item as param and push into selected items array
     * Remove it from unselected items
     */
    /**
     * Select Item
     * @param {object} item
     * @returns {void}
     * @description Function description:
     * - Gets item as param and push into selected items array
     * - Removes item from unselected items
     */
    selectItem(item) {
      const isAlreadySelected = this.selectedItems.some(
        (selectedItem) => selectedItem.id === item.id,
      )

      if (!isAlreadySelected) {
        this.selectedItems.push(item)
        this.optionsArray.forEach((record, idx) => {
          if (record.id === item.id) this.optionsArray.splice(idx, 1)
        })
        this.returnSelectedList()
      }
    },
    /**
     * Already Assigned Items
     * @description Gives the array of already Assigned items
     */
    alreadyAssigned() {
      const processed = new Set()
      this.alreadySelected.forEach((item) => {
        this.optionsArray.forEach((option) => {
          const objectToSelect = item.id === option.id ? option : item
          const isProcessed = processed.has(item.id)
          const hasAccessLevel = accessLevel(
            item,
            this.currentCampusScope,
            this.currentSectionScope,
            this.currentClassScope,
          )
          let isItemAlreadySelected = item.id === option.id

          if (
            !isProcessed &&
            ((!isItemAlreadySelected && hasAccessLevel) ||
              (isItemAlreadySelected && hasAccessLevel))
          ) {
            this.selectItem(objectToSelect)
            processed.add(item.id)
          }
        })
      })
      this.returnSelectedList()
    },
    /*
     * Get item as param and remove it from selected item
     * Add it in unselected items
     */
    /**
     * Remove Selected Item
     * @param {object} item
     * @returns {void}
     * @description Function description:
     * - Gets item as param and remove it from selected items list
     * - Adds item in unselected items
     */
    removeSelectedItem(item) {
      this.optionsArray.unshift(item)
      this.selectedItems.forEach((record, idx) => {
        if (record.id === item.id) this.selectedItems.splice(idx, 1)
      })

      this.returnSelectedList()
    },
    /**
     * Return Selected List
     * @description Function description:
     * - Emits the selected array to parent component
     * - Selected item returns by reducing it into single element if reducer apply otherwise it emits the array of selected objects
     */
    returnSelectedList() {
      var reducedData = []
      if (this.reduce || this.filterKey) {
        this.selectedItems.reduce((acc, curr, idx, arrr) => {
          reducedData.push(curr[this.reduce || this.filterKey])
        }, 0)
        if (this.filter && this.filter.option) {
          this.$emit('emitQuery', buildWhereQuery(this.filter.option, this.filter.key, reducedData))
        }
      }

      this.reduce
        ? this.$emit('update:modelValue', reducedData)
        : this.$emit('update:modelValue', this.selectedItems)
    },
    updateLockEvent(e) {
      this.$emit('updateLockValue', e)
    },
  },
}
</script>
